/**
 * Created J/03/12/2009
 * Updated S/30/12/2023
 *
 * Copyright 2008-2025 | Fabrice Creuzot (luigifab) <code~luigifab~fr>
 * https://www.luigifab.fr/
 *
 * This program is free software, you can redistribute it or modify
 * it under the terms of the GNU General Public License (GPL) as published
 * by the free software foundation, either version 2 of the license, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but without any warranty, without even the implied warranty of
 * merchantability or fitness for a particular purpose. See the
 * GNU General Public License (GPL) for more details.
 */

// examples
function myFuncA(args) {
	console.debug("It's myFuncA(...) in demo.js file.");
	apijs.dialog.dialogInformation('myFuncA', "It's [code]myFuncA(args=" + args + ")[/code] in demo.js file.");
}

function myFuncB(action, args) {
	console.debug("It's myFuncB(...) in demo.js file.");
	var post = apijs.serialize(apijs.dialog.t2);
	if (typeof action == 'boolean') {
		if (post.indexOf('invalid') > 0) {
			apijs.html('input[value="invalid"]').parentNode.lastChild.textContent = " Invalid « bad choice from myFuncB in demo.js!";
			return false;
		}
		return true;
	}
	apijs.dialog.dialogInformation('myFuncB', "It's [code]myFuncB(action=" + action + ", args=" + args + ")[/code] in demo.js file.[br]POST data: [code]" + post + "[/code]");
}

function myFuncC(imageId, args) {
	console.debug("It's myFuncC(...) in demo.js file.");
	apijs.dialog.dialogPhoto('./upload.php?image=' + imageId, 'myFuncC(image=' + imageId + ', args=' + args + ')', 'false', '');
}

function myFuncD(xhr, args, elem) {
	console.debug("It's myFuncD(...) in demo.js file.");
	if ([0, 200].has(xhr.status)) {
		elem = document.createElement('div');
		elem.innerHTML = xhr.responseText;
		elem = elem.querySelector('div.content');
		elem.setAttribute('style', 'margin-bottom:1px; height:11em; overflow-y:scroll;');
	}
	else {
		elem = document.createTextNode(xhr.status);
	}
	apijs.dialog.t2.appendChild(elem);
}

function myFuncZ(video, url) {
	console.debug("It's myFuncZ(...) in demo.js file.");
	var elem = document.createElement('source');
	elem.setAttribute('src', url);
	video.appendChild(elem);
	video.style.visibility = 'visible';
	apijs.dialog.remove('loading');
}

self.addEventListener('apijsbeforeload', function () {
	apijs.config.debug = true;
});


// documentation
function rtl() {
	self.location.href = document.querySelector('html').hasAttribute('dir') ?
		self.location.href.replace(/[?&]rtl=[0-9]+/, '') :
		self.location.href + ((self.location.href.indexOf('?') > 0) ? '&rtl=1' : '?rtl=1');
}

function pprint(go) {

	if (document.querySelectorAll('link[media="disabled"]').length < 1) {
		if (go !== true) {
			document.querySelectorAll('link[media="screen"]').forEach(function (elem) { elem.setAttribute('media', 'disabled'); });
			document.querySelectorAll('link[media="print"]').forEach(function (elem) { elem.setAttribute('media', 'screen'); });
		}
	}
	else {
		document.querySelectorAll('link[media="screen"]').forEach(function (elem) { elem.setAttribute('media', 'print'); });
		document.querySelectorAll('link[media="disabled"]').forEach(function (elem) { elem.setAttribute('media', 'screen'); });
	}

	if (go === true)
		self.setTimeout(self.print, 300);
}

function getValue(value) {

	if (value.indexOf('Func') > -1)
		return self.setTimeout(function () { apijs.config.dialog.player = myFuncZ; }, 12);
	else if (value === 'true')
		return true;
	else if (value === 'false')
		return false;
	else if (value === 'null')
		return null;
	else if (/^\d+$/.test(value))
		return parseInt(value, 10);
	else
		return value;
}

function screensUpdate(elem) {

	elem.parentNode.parentNode.parentNode.querySelectorAll('.screens img').forEach(function (img) {
		if (elem.name == 'gtk') {
			img.setAttribute('src', img.getAttribute('src').replace(/gtk[0-9]/, 'gtk' + elem.value));
		}
		else if (elem.name == 'dir') {
			img.setAttribute('src', img.getAttribute('src').replace(
				(elem.value == 'rtl') ? /(gtk[0-9])/ : /(gtk[0-9])-rtl/,
				(elem.value == 'rtl') ? '$1-rtl' : '$1'));
		}
		else {
			var file = img.getAttribute('src');
			file = file.replace('-orange', '').replace('-blue', '').replace('-green', '');
			if (elem.value.length > 0)
				file = file.replace(/(gtk[0-9](?:-rtl)?)/, '$1-' + elem.value);
			img.setAttribute('src', file);
		}
	});

	if (elem.name == 'gtk') {
		elem.parentNode.parentNode.parentNode.querySelectorAll('.screens dd').forEach(function (dd) {
			dd.textContent = dd.textContent.replace(/GTK [0-9]/, 'GTK ' + elem.value);
		});
	}
}

function screensLeft(elem) {

	var root = elem.parentNode.querySelector('dl'),
	    cur  = parseInt(root.style.marginLeft, 10),
	    max  = (elem.parentNode.querySelectorAll('dl').length - 1) * root.clientWidth * -1;

	if (isNaN(cur))
		cur = 0;

	root.style.marginLeft = (cur < 0) ? (cur + root.clientWidth) + 'px' : max + 'px';
}

function screensRight(elem) {

	var root = elem.parentNode.querySelector('dl'),
	    cur  = parseInt(root.style.marginLeft, 10),
	    max  = (elem.parentNode.querySelectorAll('dl').length - 1) * root.clientWidth * -1;

	if (isNaN(cur))
		cur = 0;

	root.style.marginLeft = (cur > max) ? (cur - root.clientWidth) + 'px' : 0;
}

function setHighlight(value, index) {

	document.querySelectorAll('.highlight').forEach(function (elem) { elem.setAttribute('class', 'highlight ' + value); });
	document.querySelectorAll('.highlight select').forEach(function (elem) { elem.selectedIndex = index; });

	localStorage.setItem('highlight_value', value);
	localStorage.setItem('highlight_index', index);
}

function onLoadDoc() {

	var value = localStorage.getItem('highlight_value'), index = localStorage.getItem('highlight_index'), selects, option;
	if (value && index)
		setHighlight(value, index);

	selects = document.querySelectorAll('select.changelang');
	if (apijs && apijs.i18n && (selects.length > 0)) {
		selects.forEach(function (select) {
			if (select.querySelectorAll('option').length === 1) {
				Object.keys(apijs.i18n.data).forEach(function (text) {
					option = document.createElement('option');
					option.setAttribute('value', text);
					option.appendChild(document.createTextNode(text));
					select.appendChild(option);
				});
			}
		});
	}

	document.querySelectorAll('div.contents select').forEach(function (select) {
		select.querySelectorAll('option').forEach(function (option, idx) {
			if (option.hasAttribute('selected'))
				select.selectedIndex = idx;
		});
	});

	document.querySelectorAll('div.controls input').forEach(function (input) {
		input.checked = input.hasAttribute('checked');
	});

	document.body.classList.add('js');
}

self.addEventListener('load', onLoadDoc);